var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Slide',{attrs:{"id":"hamburgerMenu","closeOnNavigation":true},on:{"openMenu":this.handleOpenMenu,"closeMenu":this.handleCloseMenu}},[_c('a',{attrs:{"href":"#Home"}},[_vm._v(" Home ")]),_c('a',{attrs:{"href":"#About"}},[_vm._v(" About ")]),_c('a',{attrs:{"href":"#Experience"}},[_vm._v(" Experience ")]),_c('a',{attrs:{"href":"#Skills"}},[_vm._v(" Skills ")])]),_c('nav',{staticClass:"navHolder",attrs:{"role":"navigation"}},[_c('div',{style:('border-bottom: 1px solid' + _vm.getBorder),attrs:{"id":"defaultNavbar","aria-label":"main navigation"}},[_c('a',{staticClass:"navbar-item",class:{
          navbarItemColor: !_vm.getMode,
          navbarItemColorDark: _vm.getMode,
        },attrs:{"href":"#Home"},on:{"click":function($event){return _vm.emitAnimation('home')}}},[_vm._v(" Home ")]),_c('a',{staticClass:"navbar-item",class:{
          navbarItemColor: !_vm.getMode,
          navbarItemColorDark: _vm.getMode,
        },attrs:{"href":"#About"},on:{"click":function($event){return _vm.emitAnimation('about')}}},[_vm._v(" About ")]),_c('a',{staticClass:"navbar-item",class:{
          navbarItemColor: !_vm.getMode,
          navbarItemColorDark: _vm.getMode,
        },attrs:{"href":"#Experience"},on:{"click":function($event){return _vm.emitAnimation('experience')}}},[_vm._v(" Experience ")]),_c('a',{staticClass:"navbar-item",class:{
          navbarItemColor: !_vm.getMode,
          navbarItemColorDark: _vm.getMode,
        },attrs:{"href":"#Skills"},on:{"click":function($event){return _vm.emitAnimation('skill')}}},[_vm._v(" Skills ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }